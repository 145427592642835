<template>
  <div class="home-main">
    <!-- #region Top Bar -->
    <div class="top-bar">
      <div class="top-bar-title">{{ pageTitle }}</div>
      <img class="top-bar-button" src="../assets/menu.png" @click="showMenu = !showMenu">
      <div v-if="houseLayout.length > 0" class="top-bar-button top-bar-button-right" @click="editClicked">Edit</div>
    </div>
    <!-- #endregion -->

    <!-- #region Layout area -->
    <div class="layout-container">
      <div class="wall-container" v-for="(l, i) in houseLayout" :key="i" :style="l">
        <div class="wall" :style="l.angle || {}">
          <div v-for="(itm, ind) in l.items || []" :key="ind">
            <Plug v-if="itm.type == 'plug'" :plugCount="itm.plugCount || 0" :style="itm" />
            <Door v-else-if="itm.type == 'door'" :style="itm" />
            <Windows v-else-if="itm.type == 'windows'" :leaf="itm.leaf || 0" :style="itm" />
          </div>
        </div>
      </div>
    </div>
    <!-- #endregion -->

    <!-- #region Menu -->
    <Transition name="fade">
      <div v-if="showMenu" class="menu-background" @click="showMenu = false"></div>
    </Transition>
    <Transtion name="horizontal-slide">
      <div v-if="showMenu" class="menu-container">
        <div class="top-bar">
          <div class="top-bar-title">Area</div>
          <img class="top-bar-button" src="../assets/add.png" @click="addAreaClicked">
        </div>
        <div class="area-list-container">
          <div v-if="menuLoading">Loading Areas</div>
          <div v-for="(a, i) in areaList" :key="i" class="area-container" @click="areaClicked(a.split('.')[0])">{{ a.split('.')[0] }}</div>
        </div>
      </div>
    </Transtion>
    <!-- #endregion -->

    <!-- #region Add Area -->
    <Transition name="vertical-slide">
      <div v-if="showAddArea" class="add-container">
        <div class="top-bar">
          <div class="top-bar-title">{{ areaIsEdit ? 'Edit' : 'Add' }} Area</div>
          <div class="top-bar-button top-bar-button-right" @click="closeAddAreaClicked">Close</div>
        </div>
        <div class="add-area-textarea">
          <input class="add-title" placeholder="Title" type="text" v-model="areaTitle" :disabled="areaIsEdit">
          
          <div class="add-layout">
            <div class="add-wall-block add-wall-background" v-for="(l, i) in layoutValue" :key="i">
              <div class="wall-sizing">
                <input type="text" v-model="l.name">
                <button class="danger" @click="deleteWall(i)">Delete Wall</button>
              </div>
              <div class="wall-sizing">
                <div>Left</div>
                <div>Top</div>
                <div>Width</div>
                <div>Height</div>
              </div>
              <div class="wall-sizing">
                <input type="number" v-model="l.left">
                <input type="number" v-model="l.top">
                <input type="number" v-model="l.width">
                <input type="number" v-model="l.height">
              </div>
              <div class="wall-sizing">
                <button :class="{ 'button-not-selected': l.side != 'leftWall' }" @click="wallSideClicked(i, 'leftWall')">Left Wall</button>
                <button :class="{ 'button-not-selected': l.side != 'mainWall' }" @click="wallSideClicked(i, 'mainWall')">Main Wall</button>
                <button :class="{ 'button-not-selected': l.side != 'rightWall' }" @click="wallSideClicked(i, 'rightWall')">Right Wall</button>
              </div>

              <div class="add-wall-block add-items-background" v-for="(itm, it) in l.items" :key="it">
                <div class="wall-sizing">
                  <button :class="{ 'button-not-selected': itm.type != 'plug' }" @click="itemTypeSelected(i, it, 'plug')">Plug</button>
                  <button :class="{ 'button-not-selected': itm.type != 'windows' }" @click="itemTypeSelected(i, it, 'windows')">Windows</button>
                  <button :class="{ 'button-not-selected': itm.type != 'door' }" @click="itemTypeSelected(i, it, 'door')">Door</button>
                </div>
                <div class="wall-sizing">
                  <button :class="{ 'button-not-selected': !Object.keys(itm).includes('left') }" @click="itemPositionSelected(i, it, 'left')">Left</button>
                  <button :class="{ 'button-not-selected': !Object.keys(itm).includes('right') }" @click="itemPositionSelected(i, it, 'right')">Right</button>
                  <input type="number" v-model="itm.leftRightValue" @input="leftRightValueChanged($event, i, it)">
                </div>
                <div class="wall-sizing">
                  <button :class="{ 'button-not-selected': !Object.keys(itm).includes('top') }" @click="itemPositionSelected(i, it, 'top')">Top</button>
                  <button :class="{ 'button-not-selected': !Object.keys(itm).includes('bottom') }" @click="itemPositionSelected(i, it, 'bottom')">Bottom</button>
                  <input type="number" v-model="itm.topBottomValue" @input="topBottomValueChanged($event, i, it)">
                </div>
                <div v-if="itm.type != 'plug'" class="wall-sizing">
                  <div>Width</div>
                  <div>Height</div>
                </div>
                <div v-if="itm.type != 'plug'" class="wall-sizing">
                  <input type="number" v-model="itm.width">
                  <input type="number" v-model="itm.height">
                </div>
                <div v-if="itm.type == 'plug'" class="wall-sizing">
                  <div>Plug Count</div>
                  <input type="number" v-model="itm.plugCount">
                </div>
                <div v-if="itm.type == 'windows'" class="wall-sizing">
                  <div>Leaf</div>
                  <input type="number" v-model="itm.leaf">
                </div>

                <div class="wall-sizing">
                  <button class="danger" @click="deleteItem(i, it)">Delete Item</button>
                </div>
              </div>
              <button style="margin-top: 5px" @click="addWallItemClicked(i)">Add Item</button>
            </div>
            <button @click="addWallClicked" style="margin-top: 5px">Add Wall</button>
          </div>

          <button @click="saveClicked">Save</button>
        </div>
      </div>
    </Transition>
    <!-- #endregion -->

    <!-- #region Popup -->
    <Transition name="fade">
      <div v-if="showPopup" class="popup-layout">
        <div class="popup-content-container">
          <div class="popup-title">{{ popupTitle }}</div>
          <div class="popup-content">{{ popupMessage }}</div>
          <button v-for="(b, i) in popupButtons" :key="i" @click="popupButtonClicked(b)">{{ b }}</button>
        </div>
      </div>
    </Transition>
    <!-- #endregion -->
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, onMounted, ref, watch } from 'vue';
import axios from 'axios';

export default defineComponent({
  components: {
  },
  setup() {
    const showMenu = ref(false);
    const scale = ref(2); // px per inch
    const toConvert = ref([ 'left', 'top', 'width', 'height', 'right', 'bottom' ]);
    const houseLayout = ref([]);
    const pageTitle = ref('House Layout');
    const areaTitle = ref('');
    const layoutValue = ref([]);
    
    const menuLoading = ref(true);
    const menuContainerStyle = ref({
      transform: 'translateX(-100%)'
    });
    const menuBackgroundStyle = ref({
      opacity: '0',
      zIndex: -999
    });
    const areaList = ref([]);

    const showAddArea = ref(false);
    const areaIsEdit = ref(false);

    const showPopup = ref(false);
    const popupTitle = ref('');
    const popupMessage = ref('');
    const popupButtons = ref();

    const popupResolve = ref();

    onMounted(async () => {
      refreshAreaList();
    })

    const refreshAreaList = async () => {
      menuLoading.value = true;
      areaList.value = (await axios.get('https://api.reeqzan.com/HousePlan/GetAllArea')).data;
      menuLoading.value = false;
    }
    const addAreaClicked = () => {
      areaIsEdit.value = false;
      showMenu.value = false;
      showAddArea.value = !showAddArea.value;
      areaTitle.value = '';
      layoutValue.value = [];
    }
    const saveClicked = async () => {
      if (!areaTitle.value || !layoutValue.value) {
        openPopup('Error', 'Title and layout cannot be empty');
      } else {
        try {
          // let valueToSave = JSON.parse(layoutValue.value);
          let saveResult = (await axios.post(`https://api.reeqzan.com/HousePlan/SavePlan?area=${areaTitle.value}`, layoutValue.value)).data;
          showAddArea.value = false;
          refreshAreaList();

          if (areaIsEdit.value) {
            areaClicked(pageTitle.value);
          }
        } catch {
          openPopup('Error', 'Layout values is not in a valid JSON');
        }
      }
    }
    const editClicked = () => {
      areaIsEdit.value = true;
      showAddArea.value = true;
      encodeHouseLayout(true);
      layoutValue.value = JSON.parse(JSON.stringify(houseLayout.value));
      areaTitle.value = pageTitle.value;
    }
    const areaClicked = async (areaName) => {
      houseLayout.value = [];
      let areaLayout = (await axios.get(`https://api.reeqzan.com/HousePlan/GetPlan?area=${areaName}`)).data;
      houseLayout.value = areaLayout;
      pageTitle.value = areaName;
      encodeHouseLayout();
      showMenu.value = false;
    }
    const encodeHouseLayout = (decode) => {
      houseLayout.value.forEach(l => {
        Object.keys(l).forEach(k => {
          if (toConvert.value.includes(k)) {
            l[k] = decode ? Number.parseInt(l[k]) / scale.value : `${l[k] * scale.value}px`;
          }
        });

        l.items?.forEach(i => {
          Object.keys(i).forEach(k => {
            if (toConvert.value.includes(k)) {
              i[k] = decode ? Number.parseInt(i[k]) / scale.value : `${i[k] * scale.value}px`;
            }
          })
        })
      })
    }
    const closeAddAreaClicked = () => {
      showAddArea.value = false;
      if (areaIsEdit.value) {
        encodeHouseLayout();
      }
    }
    const beautifyClicked = () => {
      try {
        layoutValue.value = JSON.stringify(JSON.parse(layoutValue.value), null, 2);
      } catch {
        openPopup('Error', 'Layout values is not in a valid JSON');
      }
    }
    const openPopup = (title, message, buttons) => {
      let prom = new Promise((res) => popupResolve.value = res);
      showPopup.value = true;
      popupTitle.value = title;
      popupMessage.value = message;
      popupButtons.value = buttons || [ 'OK' ];
      return prom;
    }
    const popupButtonClicked = (button) => {
      showPopup.value = false;
      if (popupResolve.value) {
        popupResolve.value(button);
      }
    }

    const addWallClicked = () => {
      layoutValue.value.push({
        name: 'Wall',
        side: 'mainWall',
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        angle: {
          transform: 'rotateY(0deg)',
          transformOrigin: 'center center'
        },
        items: []
      });
    }
    const addWallItemClicked = (index) => {
      layoutValue.value[index].items.push({
        type: 'plug',
        top: 0,
        left: 0,
        leftRightValue: 0,
        topBottomValue: 0,
        plugCount: 2,
        leaf: 3,
      });
    }
    const wallSideClicked = (index, side) => {
      layoutValue.value[index].side = side;
      switch (side) {
        case 'leftWall':
          layoutValue.value[index].angle = {
            transform: 'rotateY(60deg)',
            transformOrigin: '100% center'
          }
          break;
        case 'mainWall':
          layoutValue.value[index].angle = {
            transform: 'rotateY(0deg)',
            transformOrigin: 'center center'
          }
          break;
        case 'rightWall':
          layoutValue.value[index].angle = {
            transform: 'rotateY(-60deg)',
            transformOrigin: '-1px center'
          }
          break;
      }
    }
    const itemTypeSelected = (layoutIndex, itemIndex, type) => {
      layoutValue.value[layoutIndex].items[itemIndex].type = type;

      if (type == 'plug') {
        delete layoutValue.value[layoutIndex].items[itemIndex].height;
        delete layoutValue.value[layoutIndex].items[itemIndex].width;
      } else {
        layoutValue.value[layoutIndex].items[itemIndex].height = 0;
        layoutValue.value[layoutIndex].items[itemIndex].width = 0;
      }
    }
    const itemPositionSelected = (layoutIndex, itemIndex, pos) => {
      if (pos == 'right') {
        delete layoutValue.value[layoutIndex].items[itemIndex].left;
        layoutValue.value[layoutIndex].items[itemIndex].right = layoutValue.value[layoutIndex].items[itemIndex].leftRightValue;
      } else if (pos == 'left') {
        delete layoutValue.value[layoutIndex].items[itemIndex].right;
        layoutValue.value[layoutIndex].items[itemIndex].left = layoutValue.value[layoutIndex].items[itemIndex].leftRightValue;
      } else if (pos == 'bottom') {
        delete layoutValue.value[layoutIndex].items[itemIndex].top;
        layoutValue.value[layoutIndex].items[itemIndex].bottom = layoutValue.value[layoutIndex].items[itemIndex].topBottomValue;
      } else if (pos == 'top') {
        delete layoutValue.value[layoutIndex].items[itemIndex].bottom;
        layoutValue.value[layoutIndex].items[itemIndex].top = layoutValue.value[layoutIndex].items[itemIndex].topBottomValue;
      }
    }
    const leftRightValueChanged = (e, layoutIndex, itemIndex) => {
      if (Object.keys(layoutValue.value[layoutIndex].items[itemIndex]).includes('left')) {
        layoutValue.value[layoutIndex].items[itemIndex].left = e.target.value ? Number(e.target.value) : 0;
      } else {
        layoutValue.value[layoutIndex].items[itemIndex].right = e.target.value ? Number(e.target.value) : 0;
      }
    }
    const topBottomValueChanged = (e, layoutIndex, itemIndex) => {
      if (Object.keys(layoutValue.value[layoutIndex].items[itemIndex]).includes('top')) {
        layoutValue.value[layoutIndex].items[itemIndex].top = e.target.value ? Number(e.target.value) : 0;
      } else {
        layoutValue.value[layoutIndex].items[itemIndex].bottom = e.target.value ? Number(e.target.value) : 0;
      }
    }
    const deleteWall = async (layoutIndex) => {
      const deleteWall = await openPopup('Delete Wall', 'Are you sure you want to delete a wall?', [ 'Delete', 'Cancel' ]);
      if (deleteWall == 'Delete') {
        layoutValue.value.splice(layoutIndex, 1);
      }
    }
    const deleteItem = async (layoutIndex, itemIndex) => {
      const deleteItem = await openPopup('Delete Item', 'Are you sure you want to delete an item?', [ 'Delete', 'Cancel' ]);
      if (deleteItem == 'Delete') {
        layoutValue.value[layoutIndex].items.splice(itemIndex, 1);
      }
    }
    
    watch(showMenu, (val) => {
      menuContainerStyle.value.transform = `translateX(${val ? '0' : '-100%'})`;
      menuBackgroundStyle.value.opacity = val ? '1' : '0';
      if (val) {
        menuBackgroundStyle.value.zIndex = '';
      } else {
        setTimeout(() => {
          menuBackgroundStyle.value.zIndex = '-999';
        }, 300);
      }
    })
    
    return {
      showMenu,
      scale,
      houseLayout,
      pageTitle,
      areaTitle,
      layoutValue,

      menuLoading,
      menuContainerStyle,
      menuBackgroundStyle,
      areaList,

      showAddArea,
      areaIsEdit,

      showPopup,
      popupTitle,
      popupMessage,
      popupButtons,
      popupButtonClicked,

      addWallClicked,
      addWallItemClicked,
      wallSideClicked,
      itemTypeSelected,
      itemPositionSelected,
      leftRightValueChanged,
      topBottomValueChanged,
      deleteWall,
      deleteItem,

      addAreaClicked,
      saveClicked,
      editClicked,
      areaClicked,
      closeAddAreaClicked,
      beautifyClicked,
    }
  },
})
</script>

<style scoped>
.home-main {
  height: 100vh;
  width: 100vw;
}
.top-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid gray;
  height: 50px;
  max-height: 50px;
  min-height: 50px;
  box-shadow: 0 0 10px 0px gray;
}
.top-bar-title {
  width: 100%;
  text-align: center;
  max-width: 80%;
}
.top-bar-button {
  position: absolute;
  left: 10px;
  height: 60%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: blue;
}
.top-bar-button-right {
  left: unset;
  right: 10px;
}
.menu-background, .menu-container {
  transition: 0.3s;
}
.menu-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(128, 128, 128, 0.4);
}
.menu-container {
  background-color: white;
  width: 60%;
  max-width: 300px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  transform: translateX(0);
}
.area-list-container {
  height: calc(100% - 61px);
  width: calc(100% - 10px);
  overflow: auto;
  padding: 5px;
}
.area-container {
  width: calc(100% - 20px);
  padding: 10px;
  cursor: pointer;
  box-shadow: inset 0 0 5px black;
}
.area-container:not(:first-child) {
  margin-top: 5px;
}
.add-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
  background-color: white;
  transition: 0.3s;
  transform: translateY(0);
}
.add-area-textarea {
  height: calc(100% - 71px);
  width: calc(100% - 20px);
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.add-layout {
  height: 100%;
  margin-bottom: 5px;
  overflow: auto;
  padding: 5px;
  padding-top: 0;
  border: 1px solid gray;
}
.add-layout button {
  padding: 5px;
}
.add-area-textarea textarea {
  height: 100%;
  margin-bottom: 5px;
  resize: none;
}
.add-area-textarea .add-title {
  text-align: center;
  padding: 5px;
  margin-bottom: 5px;
}
.add-area-textarea textarea:focus, .add-area-textarea input:focus {
  outline: none;
}
.layout-container {
  position: relative;
  height: calc(100% - 51px);
  width: 100%;
  overflow: auto;
}
.beautify-button {
  position: absolute;
  right: 15px;
  top: 50px;
}
.popup-layout {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(128, 128, 128, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.popup-content-container {
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  width: 90%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
}
.popup-title {
  font-weight: bold;
  width: 100%;
  text-align: center;
}
.popup-content {
  margin: 10px 0;
}

.add-wall-block {
  padding: 5px;
  margin-top: 5px;
}
.add-wall-background {
  background-color: aquamarine;
}
.add-items-background {
  background-color: aliceblue;
}
.add-wall-block input {
  text-align: center;
  padding: 3px;
  width: calc(100% - 10px);
  background-color: transparent;
  border: 1px solid gray;
}
.add-wall-block .wall-sizing {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 2px 0;
}
.wall-sizing div {
  width: 100%;
  text-align: center;
}
.wall-sizing .danger {
  background-color: indianred;
  color: aliceblue;
}
.wall-sizing input, .wall-sizing button {
  width: 100%;
}
.wall-sizing .button-not-selected {
  background-color: transparent;
  text-decoration: line-through;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.vertical-slide-enter-active,
.vertical-slide-leave-active {
  transition: 0.3s;
}
.vertical-slide-enter-from,
.vertical-slide-leave-to {
  transform: translateY(100%);
}
.horizontal-slide-enter-active,
.horizontal-slide-leave-active {
  transition: 0.3s;
}
.horizontal-slide-enter-from,
.horizontal-slide-leave-to {
  transform: translateX(-100%);
}
</style>