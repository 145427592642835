<template>
  <div class="windows-main">
    <div class="windows-leaf" v-for="l in props.leaf" :key="l">
      <div class="windows-glass" v-for="g in 1" :key="g">
        <div class="glass-ind1"></div>
        <div class="glass-ind2"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref } from 'vue';

export default defineComponent({
  components: {
  },
  props: {
    leaf: { type: Number, default: 5 },
  },
  setup(props) {
    const leafHeight = ref(3);
    const leafWidth = ref(1.5);

    return {
      leafHeight,
      leafWidth,

      props,
    }
  },
})
</script>

<style scoped>
.windows-main {
  display: flex;
  position: absolute;
}
.windows-leaf {
  border-style: solid;
  border-width: 3px;
  border-color: gray;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.glass-ind1, .glass-ind2 {
  position: relative;
  top: 30%;
  height: 3%;
  width: 80%;
  background-color: gray;
  margin: 10%;
  transform: rotate(-45deg);
}
.glass-ind1 {
  width: 50%;
}
.windows-glass {
  height: 99%;
  width: 99%;
  background-color: white;
}
</style>