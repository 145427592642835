<template>
  <div class="plug-main">
    <div class="plug-box" v-for="i in props.plugCount" :key="i" :style="{
      height: `${size*props.scale}px`,
      width: `${size*props.scale}px`,
    }">
      <div class="plug-container">
        <div class="plug-hole-vertical"></div>
      </div>
      <div class="plug-container">
        <div class="plug-hole-horizontal"></div>
        <div class="plug-hole-horizontal"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref } from 'vue';

export default defineComponent({
  components: {
  },
  props: {
    scale: { type: Number, default: 2 },
    plugCount: { type: Number, default: 0 },
  },
  setup(props) {
    const size = ref(3.36);

    return {
      props,

      size,
    }
  },
})
</script>

<style scoped>
.plug-main {
  display: flex;
  align-items: center;
  position: absolute;
}
.plug-box {
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
}
.plug-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.plug-hole-vertical, .plug-hole-horizontal {
  background-color: black;
}
.plug-hole-vertical {
  width: 10%;
  height: 60%;
  background-color: black;
}
.plug-hole-horizontal {
  width: 20%;
  height: 30%;
  background-color: black;
}
</style>