<template>
  <div class="door-main">
    <div v-for="b in 6" :key="b" class="door-box"></div>
    <div class="door-handle"></div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref } from 'vue';

export default defineComponent({
  components: {
  },
  props: {
  },
  setup(props) {
    const doorHeight = ref(7.1);
    const doorWidth = ref(3.1);

    return {
      props,

      doorHeight,
      doorWidth,
    }
  },
})
</script>

<style scoped>
.door-main {
  background-color: brown;
  border-width: 1px 1px 0 1px;
  border-color: gray;
  border-style: solid;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  bottom: 0;
  box-sizing: border-box;
}
.door-box {
  height: 20%;
  width: 35%;
  box-shadow: inset 0 0 10px black;
}
.door-handle {
  height: 15px;
  width: 15px;
  background-color: gray;
  position: absolute;
  left: 5px;
  top: 50%;
  border-radius: 50%;
  box-shadow: 0 0 10px 2px black;
}
</style>