import { createApp } from 'vue';
import App from './App.vue';

import { createRouter, createWebHistory } from 'vue-router';

import HomePage from './pages/HomePage.vue';

import Plug from './components/Plug.vue';
import Windows from './components/Windows.vue';
import Door from './components/Door.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/Home',
      name: 'Main'
    },
    {
      path: '/Home',
      name: 'Home',
      component: HomePage
    },
  ]
})

const app = createApp(App)
  .use(router);

app
  .component('Plug', Plug)
  .component('Windows', Windows)
  .component('Door', Door);

router.isReady().then(() => {
  app.mount('#app');
});
