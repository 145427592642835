<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core'
export default {
  name: 'app',
  setup() {
    onMounted(() => {
      document.title = 'Azizi & Alya Home';
    })
  }
}
</script>

<style scoped>
#app {
  position: relative;
}
</style>

<style>
body {
  margin: 0;
  overflow: hidden;
}
.wall-container {
  position: absolute;
  transform-style: preserve-3d;
  overflow: visible;
  perspective: 1000px;
}
.wall-container .wall {
  width: 100%;
  height: 100%;
  border: 1px solid black;
  position: absolute;
}
.wall .item {
  position: absolute;
}
button {
  padding: 10px;
  border-radius: 5px;
  border-width: 1px;
  background-color: rgb(223, 223, 255);
  cursor: pointer;
}
</style>